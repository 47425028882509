var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reserve-in-store"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"reserve-in-store__info-block"},[_c('p',{staticClass:"reserve-in-store__info-block__description",domProps:{"innerHTML":_vm._s(_vm.$t('reserveForm.firstBlockDescription'))}})])]),_c('div',{staticClass:"col-12"},[_c('product-price',{ref:"cpPrice",staticClass:"d-none",attrs:{"product":_vm.product}}),_c('form',{ref:"reserve-in-store__form",staticClass:"reserve-in-store__form",attrs:{"data-vv-scope":"reserve-in-store__form"},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"groupFirstname","label":_vm.$t('address.FirstName') + ' *',"label-for":"firstname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"firstname","type":"text","placeholder":_vm.$t('address.FirstName'),"validations":[
                  {
                    condition: _vm.errors.has('reserve-in-store__form.firstname'),
                    text: _vm.errors.first('reserve-in-store__form.firstname')
                  }
                ]},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('b-form-group',{attrs:{"id":"groupLastname","label":_vm.$t('address.LastName') + ' *',"label-for":"lastname"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"lastname","type":"text","placeholder":_vm.$t('address.LastName'),"validations":[
                  {
                    condition: _vm.errors.has('reserve-in-store__form.lastname'),
                    text: _vm.errors.first('reserve-in-store__form.lastname')
                  }
                ]},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1),_c('b-form-group',{attrs:{"id":"groupEmail","label":_vm.$t('email') + ' *',"label-for":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"name":"email","type":"text","autocomplete":"username","placeholder":_vm.$t('address.Email'),"validations":[
                  {
                    condition: _vm.errors.has('reserve-in-store__form.email'),
                    text: _vm.errors.first('reserve-in-store__form.email')
                  }
                ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('b-form-group',{attrs:{"id":"groupPhoneNumber","label":_vm.$t('address.PhoneNumber') + ' *',"label-for":'phone'}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|phoneNumber'),expression:"'required|phoneNumber'"}],attrs:{"name":"phone","autocomplete":"telephone","type":"tel","placeholder":_vm.$t('address.PhoneNumber'),"validations":[
                  {
                    condition: _vm.errors.has('reserve-in-store__form.phone'),
                    text: _vm.errors.first('reserve-in-store__form.phone')
                  }
                ]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"groupProductName","label":_vm.$t('reserveForm.productName') + ' *',"label-for":"productName"}},[_c('base-input',{attrs:{"name":"productName","type":"text","disabled":"","placeholder":_vm.$t('reserveForm.productName')},model:{value:(_vm.form.productName),callback:function ($$v) {_vm.$set(_vm.form, "productName", $$v)},expression:"form.productName"}})],1),_c('b-form-group',{attrs:{"id":"groupProductSku","label":_vm.$t('reserveForm.productSku') + ' *',"label-for":"productSku"}},[_c('base-input',{attrs:{"name":"productSku","type":"text","disabled":"","placeholder":_vm.$t('reserveForm.productSku')},model:{value:(_vm.form.productSku),callback:function ($$v) {_vm.$set(_vm.form, "productSku", $$v)},expression:"form.productSku"}})],1),_c('b-form-group',{attrs:{"id":"groupProductSize","label":_vm.$t('reserveForm.productSize') + ' *',"label-for":"productSize"}},[_c('base-input',{attrs:{"name":"productSize","type":"text","disabled":"","placeholder":_vm.$t('reserveForm.productSize')},model:{value:(_vm.form.productSize),callback:function ($$v) {_vm.$set(_vm.form, "productSize", $$v)},expression:"form.productSize"}})],1),_c('b-form-group',{attrs:{"id":"groupPrivacy","label-for":"privacy"}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:('required:true'),expression:"'required:true'"}],attrs:{"name":"privacy","use_prevent":false,"label":_vm.labelLinkPrivacy,"validations":[
                  {
                    condition: _vm.errors.has('reserve-in-store__form.privacy'),
                    text: _vm.errors.first('reserve-in-store__form.privacy')
                  }
                ]},model:{value:(_vm.form.privacy),callback:function ($$v) {_vm.$set(_vm.form, "privacy", $$v)},expression:"form.privacy"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverValidation),expression:"serverValidation"}],staticClass:"form-errors is-invalid"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.serverValidation)}})]),_c('div',{staticClass:"block-submit text-center d-md-flex align-items-center"},[_c('button',{staticClass:"btn btn-primary w-100",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('send')))])])],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }