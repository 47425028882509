<template>
  <div class="reserve-in-store">
    <div class="row">
      <div class="col-12">
        <div class="reserve-in-store__info-block">
          <!-- <h4 class="reserve-in-store__info-block__title" v-t="'reserveForm.firstBlockTitle'"></h4> -->
          <p class="reserve-in-store__info-block__description" v-html="$t('reserveForm.firstBlockDescription')"></p>
        </div>
        <!-- <div class="reserve-in-store__info-block">
          <h4 class="reserve-in-store__info-block__title" v-t="'reserveForm.secondBlockTitle'"></h4>
          <p class="reserve-in-store__info-block__description" v-t="'reserveForm.secondBlockDescription'"></p>
        </div> -->
      </div>
      <div class="col-12">
        <product-price class="d-none" ref="cpPrice" :product="product"></product-price>
        <form
          class="reserve-in-store__form"
          ref="reserve-in-store__form"
          data-vv-scope="reserve-in-store__form"
          @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col md="6">
              <b-form-group id="groupFirstname" :label="$t('address.FirstName') + ' *'" label-for="firstname">
                <base-input
                  name="firstname"
                  v-validate="'required'"
                  v-model="form.firstname"
                  type="text"
                  :placeholder="$t('address.FirstName')"
                  :validations="[
                    {
                      condition: errors.has('reserve-in-store__form.firstname'),
                      text: errors.first('reserve-in-store__form.firstname')
                    }
                  ]"
                >
                </base-input>
              </b-form-group>
              <b-form-group id="groupLastname" :label="$t('address.LastName') + ' *'" label-for="lastname">
                <base-input
                  name="lastname"
                  v-validate="'required'"
                  v-model="form.lastname"
                  type="text"
                  :placeholder="$t('address.LastName')"
                  :validations="[
                    {
                      condition: errors.has('reserve-in-store__form.lastname'),
                      text: errors.first('reserve-in-store__form.lastname')
                    }
                  ]"
                >
                </base-input>
              </b-form-group>
              <b-form-group id="groupEmail" :label="$t('email') + ' *'" label-for="email">
                <base-input
                  name="email"
                  v-validate="'required|email'"
                  v-model="form.email"
                  type="text"
                  autocomplete="username"
                  :placeholder="$t('address.Email')"
                  :validations="[
                    {
                      condition: errors.has('reserve-in-store__form.email'),
                      text: errors.first('reserve-in-store__form.email')
                    }
                  ]"
                >
                </base-input>
              </b-form-group>
              <b-form-group id="groupPhoneNumber" :label="$t('address.PhoneNumber') + ' *'" :label-for="'phone'">
                <base-input
                  name="phone"
                  autocomplete="telephone"
                  v-validate="'required|phoneNumber'"
                  v-model="form.phone"
                  type="tel"
                  :placeholder="$t('address.PhoneNumber')"
                  :validations="[
                    {
                      condition: errors.has('reserve-in-store__form.phone'),
                      text: errors.first('reserve-in-store__form.phone')
                    }
                  ]"
                >
                </base-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group id="groupProductName" :label="$t('reserveForm.productName') + ' *'" label-for="productName">
                <base-input
                  name="productName"
                  v-model="form.productName"
                  type="text"
                  disabled
                  :placeholder="$t('reserveForm.productName')"
                >
                </base-input>
              </b-form-group>
              <b-form-group id="groupProductSku" :label="$t('reserveForm.productSku') + ' *'" label-for="productSku">
                <base-input
                  name="productSku"
                  v-model="form.productSku"
                  type="text"
                  disabled
                  :placeholder="$t('reserveForm.productSku')"
                >
                </base-input>
              </b-form-group>
              <b-form-group id="groupProductSize" :label="$t('reserveForm.productSize') + ' *'" label-for="productSize">
                <base-input
                  name="productSize"
                  v-model="form.productSize"
                  type="text"
                  disabled
                  :placeholder="$t('reserveForm.productSize')"
                >
                </base-input>
              </b-form-group>
              <b-form-group id="groupPrivacy" label-for="privacy">
                <base-check-box
                  name="privacy"
                  v-validate="'required:true'"
                  v-model="form.privacy"
                  :use_prevent="false"
                  :label="labelLinkPrivacy"
                  :validations="[
                    {
                      condition: errors.has('reserve-in-store__form.privacy'),
                      text: errors.first('reserve-in-store__form.privacy')
                    }
                  ]">
                </base-check-box>
              </b-form-group>
              <div class="form-errors is-invalid" v-show="serverValidation">
                <p v-html="serverValidation"></p>
              </div>
              <div class="block-submit text-center d-md-flex align-items-center">
                <button type="submit" class="btn btn-primary w-100">{{ $t('send') }}</button>
              </div>
            </b-col>
          </b-row>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ProductPrice from '@/components/Listing/ProductPrice'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import get from 'lodash/get'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import { toast } from '@/modules/notifications'
import { EventBus } from '@/helpers'
import { pathTranslate } from '@/helpers/routeHelpers'
export default {
  inject: ['$validator'],
  name: 'ReserveInStore',
  props: {
    store: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    size: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: {
        customerId: '',
        cegidCustomerId: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        productName: this.product.name,
        productSku: this.product.sku,
        productSize: this.size.label,
        privacy: false
      },
      serverValidation: false
    }
  },
  computed: {
    getProductByVariant () {
      // loop variant of product with every filter active
      let product = JSON.parse(JSON.stringify(this.product))
      let filterActive = JSON.parse(JSON.stringify(this.$store.state.product.filterActive))
      if (product.variants) {
        let variants = product.variants
        for (let i in filterActive) {
          variants = filter(variants, function (variant) {
            let attr = find(variant.attributes, function (attibute) {
              if (attibute.value_index === filterActive[i]) return true
              return false
            })
            return attr
          })
        }
        if (variants.length) {
          product = map(variants, 'product')
        }
      }
      return product // is Array
    },
    getPhone () {
      if (this.$store.getters['user/hasAddresses']) {
        let addresses = get(this.$store, 'state.user.current.addresses', [])
        let defaultAddress = addresses.find(address => address.default_shipping)
        if (defaultAddress) {
          return defaultAddress.telephone
        } else if (addresses[0]) {
          return addresses[0].telephone
        } else {
          return ''
        }
      }
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router
        .resolve(
          {
            name: 'genericTerms',
            params: {
              slug: pathTranslate({ path: 'privacy-policy' }),
              lang: this.$store.state.ui.storeViewCode
            }
          }
        )
      return this.$t('I agree to the Terms and Conditions', { linkPrivacy: linkPrivacy.href })
    }
  },
  methods: {
    handleSubmit (evt) {
      const self = this
      if (evt) {
        evt.preventDefault()
      }
      self.serverValidation = false
      this.$validator.validateAll('reserve-in-store__form')
        .then(isValid => {
          if (isValid) {
            self.$store.commit('ui/showLoader')
            let params = {
              'customer_id': self.form.customerId,
              'firstname': self.form.firstname,
              'lastname': self.form.lastname,
              'email': self.form.email,
              'telephone': self.form.phone,
              'sku': self.product.sku,
              'ean': self.getProductByVariant[0].sku,
              'price': get(self.$refs, 'cpPrice.productPrice.currentPrice', undefined),
              'special_price': self.product.special_price,
              'currency': self.$store.state.storeConfig.config.base_currency_code,
              'boutique': self.store.code,
              'store_email': self.store.email,
              'product_image': self.product.image.url,
              'product_size': self.size.label,
              'store': self.store
            }
            this.$store.dispatch('storeLocator/sendReservation', params)
              .then(res => {
                EventBus.$emit('dynamicModal:close')
                toast.success(self.$t('Request successful'))
              })
              .catch(error => {
                toast.error(error)
              })
              .finally(() => {
                self.$store.commit('ui/hideLoader')
              })
          }
        })
    }
  },
  mounted () {
    let user = get(this.$store, 'state.user.current', undefined)
    if (this.$store.getters['user/isLogged'] && user) {
      this.form.customerId = user.id
      this.form.cegidCustomerId = null
      this.form.firstname = user.firstname
      this.form.lastname = user.lastname
      this.form.email = user.email
      this.form.phone = this.getPhone
    }
  },
  components: {
    BaseInput,
    BaseCheckBox,
    ProductPrice
  }
}
</script>

<style>

</style>
